/*///////////////////////////////////////////
//	text-align,	vertical-align, float, clear
///////////////////////////////////////////*/
.alignLeft { text-align: left !important; }
.alignRight { text-align: right !important; }
.alignCenter { text-align: center !important; }
.vaTop { vertical-align: top !important; }
.case { overflow: hidden; zoom: 1; }
.floatRight { float: right; }
.floatLeft { float: left; }
.msie6 .floatRight { float: right; display: inline !important; }
.msie6 .floatLeft { float: left; display: inline !important; }
.vaMiddle { vertical-align: middle !important; }
.vaBottom { vertical-align: bottom !important; }
.floatNone { float: none !important; }
.floatLeft { float: left !important; }
.floatNone { float: none !important; }
.clearLeft { clear: left !important; }
.clearRight { clear: right !important; }
.clearBoth { clear: both !important; }
.delete { display: none !important; }
.borderBox {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
}
/*///////////////////////////////////////////
//  width
///////////////////////////////////////////*/
$i: 10;
@while $i <= 960 {
    .w#{$i} { width: $i + px !important; }
    $i: $i + 10;
}

.w100p {
    width: 100%;
}
.w50p {
    width: 50%;
}
.w33p {
    width: 33.33333%;
}
.w25p {
    width: 25%;
}
/*///////////////////////////////////////////
//  height
///////////////////////////////////////////*/
$i: 10;
@while $i <= 960 {
    .h#{$i} { height: $i + px !important; }
    $i: $i + 10;
}
.h100p {
    height: 100%;
}
/*///////////////////////////////////////////
//	margin
///////////////////////////////////////////*/
//top
$i: 0;
@while $i <= 30 {
    .mt#{$i} { margin-top: $i + px !important; }
    $i: $i + 1;
}
$i: 35;
@while $i <= 100 {
    .mt#{$i} { margin-top: $i + px !important; }
    $i: $i + 5;
}

//right
$i: 0;
@while $i <= 30 {
    .mr#{$i} { margin-right: $i + px !important; }
    $i: $i + 1;
}
$i: 35;
@while $i <= 100 {
    .mr#{$i} { margin-right: $i + px !important; }
    $i: $i + 5;
}

//bottom
$i: 0;
@while $i <= 30 {
    .mb#{$i} { margin-bottom: $i + px !important; }
    $i: $i + 1;
}
$i: 35;
@while $i <= 100 {
    .mb#{$i} { margin-bottom: $i + px !important; }
    $i: $i + 5;
}

//left
$i: 0;
@while $i <= 30 {
    .ml#{$i} { margin-left: $i + px !important; }
    $i: $i + 1;
}
$i: 35;
@while $i <= 100 {
    .ml#{$i} { margin-left: $i + px !important; }
    $i: $i + 5;
}
/*///////////////////////////////////////////
//	padding
///////////////////////////////////////////*/
//top
$i: 0;
@while $i <= 30 {
    .pt#{$i} { padding-top: $i + px !important; }
    $i: $i + 1;
}
$i: 35;
@while $i <= 100 {
    .pt#{$i} { padding-top: $i + px !important; }
    $i: $i + 5;
}

//right
$i: 0;
@while $i <= 30 {
    .pr#{$i} { padding-right: $i + px !important; }
    $i: $i + 1;
}
$i: 35;
@while $i <= 100 {
    .pr#{$i} { padding-right: $i + px !important; }
    $i: $i + 5;
}

//bottom
$i: 0;
@while $i <= 30 {
    .pb#{$i} { padding-bottom: $i + px !important; }
    $i: $i + 1;
}
$i: 35;
@while $i <= 100 {
    .pb#{$i} { padding-bottom: $i + px !important; }
    $i: $i + 5;
}

//left
$i: 0;
@while $i <= 30 {
    .pl#{$i} { padding-left: $i + px !important; }
    $i: $i + 1;
}
$i: 35;
@while $i <= 100 {
    .pl#{$i} { padding-left: $i + px !important; }
    $i: $i + 5;
}

//all
$i: 0;
@while $i <= 30 {
    .p#{$i} { padding: $i + px !important; }
    $i: $i + 1;
}
