@import "base";

#footer {
	width: 100%;
	position: relative;
	background: #fff;
	border-top: 2px solid $colorGray;
	.topper {
		a {
			display: block;
			text-align: center;
			padding: 16px;
		}
	}
	.middle {
		border-top: 1px solid $colorGrayLight;
		padding: 2px;
		h2 {
			background: $colorGrayLight;
			text-align: center;
			padding: 12px 0 10px;
			@include font_size(9);
			font-weight: bold;
			margin: 0 0 10px;
		}
		.symbol {
			margin: 0 10px;
			a {
				display: block;
				position: relative;
				text-align: center;
				padding: 15px 48px 20px 24px;
				&:after {
					content: url(/src/img/icon/icon_blank_a.png);
					top: 0;
					right: 0;
					position: absolute;
				}
			}
		}
		.contact {
			margin: 0 10px 10px;
			a {
				display: block;
				border: 1px solid $colorGray;
				padding: 15px 0 14px;
				text-align: center;
				text-decoration: none;
				font-weight: bold;
				@include font_size(11);
			}
		}
	}
	.bottom {
		background-color: #025cad;
		padding: 20px 0 35px;
		ul {
			text-align: center;
			margin-bottom: 18px;
			li {
				display: inline-block;
				a {
					color: #fff;
					display: inline-block;
					padding: 0 10px;
					text-decoration: none;
					@include font_size(9);
				}
				border-left: 1px solid #fff;
				&:first-child {
					border: none;
					a {
						padding-left: 0;
					}
				}
				&:last-child {
					a {
						padding-right: 0;
					}
				}
			}
		}
		.copyright{
			text-align: center;
			small {
				@include font_size(9);
				color: #fff;
				font-weight: bold;
				display: inline-block;
			}
			span {
				@include font_size(8);
				font-weight: bold;
				display: inline-block;
				 color: #fff;
			}
		}
	}
}