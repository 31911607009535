@import "base";

p {
	#contents & {
		@include font_size(11);
		margin: 0 0 10px;
		line-height: 1.5;
		em {
			font-style: normal;
			color: #c00;
			font-weight: bold;
		}
	}
}
h1 {
	margin: 0 0 10px;
}
p,h2,h3,h4,h5,h6 {
	&.h_a {
		color: #333;
		@include font_size(13);
		font-weight: bold;
		line-height: 1.5;
		margin-bottom: 10px;
	}
	&.h_b {
		color: #ff4c00;
		@include font_size(13);
		font-weight: bold;
		line-height: 1.5;
		margin-bottom: 10px;
	}
}
input[type="text"],input[type="password"],input[type="number"] {
	@include borderBox;
	@include appearance;
	@include font_size(13);
	@include border_radius(4px);
	@include box_shadow(0 1px 1px rgba(195, 195, 195, 0.3));
	padding: 9px;
	background-color: #fff;
	border: 1px solid #ccc;
}
select {
	@include borderBox;
	@include appearance;
	@include font_size(13);
	@include border_radius(4px);
	@include box_shadow(0 2px 3px rgba(0, 0, 0, 0.24), 0 0 3px rgba(204, 204, 204, 0.75));
	padding: 9px;
	padding-right: 35px;
	background-color: #fff;
	background-image: url(/src/img/marker/bg_select.png);
	background-repeat: no-repeat;
	background-position: right center;
	outline: none !important;
}

textarea {
	background-color: white;
	border: 1px solid #ccc;
	padding: 5px;
	@include borderBox;
	@include box_shadow(0 1px 1px rgba(195, 195, 195, 0.75), inset 2.5px 4.3px 5px rgba(204, 204, 204, 0.15));
}

img.rover {
	cursor: pointer;
}


hr.groove {
	border-top: 2px solid #e6dfcd;
	border-bottom: 1px solid #fff;
	margin: 10px 0;
}

ul {
	&.ul_a {
		margin-bottom: 20px;
		li {
			margin-bottom: 15px;
			&:last-child {
				margin-bottom: 0;
			}
			a {
				@include font_size(11);
				line-height: 1.5;
				padding-left: 12px;
				text-decoration: none;
				font-weight: bold;
				display: inline-block;
				position: relative;
				min-height: 9px;
				background: {
					image: url(/src/img/marker/bullet_b.png);
					repeat: no-repeat;
					size: 6px 9px;
					position: 2px 3px;
				}
			}
		}
	}

	&.ul_tab_2 {
		margin: 0 5px;
		@include borderBox;
		border: 1px solid #cdcccc;
		background: #fff;
		@include border_radius(5px);
		overflow: hidden;
		li {
			width: 50%;
			@include borderBox;
			float: left;
			padding: 10px 0;
			text-align: center;
			color: #015cac;
			@include font_size(11);
			&.active {
				background: #e6f2f8;
				font-weight: bold;
			}
			& + li {
				border-left: 1px solid #cdcccc;
			}
		}
	}

	&.ul_disc {
		margin: 0 10px 20px;
		padding-left: 30px;
		li {
			@include font_size(12);
			margin-bottom: 10px;
			list-style-type: disc;
			list-style-position: outside;
			line-height: 1.3
		}
	}
}

dl {
	&.dl_a {
		margin: 0 0 10px;
		background-color: white;
		border: 4px solid #fcedc2;
		border-radius: 3px;
		@include border_radius(3px);
		@include borderBox;
		padding: 20px 12px;
		dt {
			@include font_size(13);
			font-weight: bold;
			margin-bottom: 5px;
			line-height: 1.5;
			span {
				@include font_size(11);
			}
		}
		dd {
			margin-bottom: 20px;
			line-height: 1.5;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

button, input[type="button"], input[type="submit"], a {
	&.btn_a {
		@include appearance;
		display: block;
		text-decoration: none;
		text-align: center;
		width: 100%;
		padding: 15px 0;
		background-color: #f26432;
		border-radius: 2px;
		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
		opacity: 0.94;
		text-shadow: 1px 1px 1px #c30;
		color: #fff;
		font-weight: bold;
		@include font_size(13);
	}
	&.btn_b {
		@include appearance;
		display: inline-block;
		@include borderBox;
		width: 100%;
		padding: 18px 15px;
		border: 1px solid #ccc;
		@include font_size(13);
		font-weight: bold;
		text-align: center;
		text-decoration: none;
		background: #FFF;
		background: -webkit-gradient(linear, left top, left bottom, color-stop(1.00, #f3f3f3), color-stop(0.00, #fff));
		background: -webkit-linear-gradient(#fff, #f3f3f3);
		background: -moz-linear-gradient(#fff, #f3f3f3);
		background: -o-linear-gradient(#fff, #f3f3f3);
		background: -ms-linear-gradient(#fff, #f3f3f3);
		background: linear-gradient(#fff, #f3f3f3);
		@include border_radius(3px);
		&.s {
			width: auto;
			padding: 10px;
		}
	}
	&.btn_c {
		@include appearance;
		display: inline-block;
		@include borderBox;
		width: 100%;
		padding: 15px;
		border: 1px solid #ccc;
		@include font_size(11);
		font-weight: bold;
		text-align: center;
		text-decoration: none;
		background: #FFF;
	}
	&.btn_d {
		display: inline-block;
		width: 100%;
		background-color: #f26432;
		@include borderBox;
		@include border_radius(3px);
		@include box_shadow(0 1px 1px rgba(0, 0, 0, 0.15));
		text-decoration: none;
		padding: 18px 10px;
		text-align: center;
		font-weight: bold;
		text-shadow: 1px 1px 1px #c30;
		color: #fff;
		@include font_size(13);
	}
}
#contents {
	div, form {
		&.box_a {
			display: block;
			padding: 10px 10px 15px;
			margin-bottom: 10px;
		}
		&.box_b {
			display: block;
			background: #FFF;
			border-top: 1px solid #e9e9ea;
			border-bottom: 2px solid #e9e9ea;
			margin-bottom: 10px;
			h2 {
				@include font_size(13);
				border-bottom: 1px solid #e9e9ea;
				padding: 15px 10px;
				margin-bottom: 10px;
				font-weight: bold;
				@include box_shadow(rgba(0,0,0,.1) 0 1px 1px 0);
			}
			p {
				@include font_size(12);
				margin: 0 10px 20px;
			}
			h3.h_a {
				color: #333;
				@include font_size(12);
				margin: 0 10px 5px;
			}
			h3.h_b {
				padding: 10px 0;
				border-top: 1px solid #e2e2e3;
				border-bottom: 1px solid #e2e2e3;
				margin-bottom: 10px;
				span {
					display: block;
					@include font_size(13);
					font-weight: bold;
					padding-left: 10px;
					border-left: 2px solid #055eae;
				}
			}
		}
	}
}
