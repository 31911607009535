@import "base", "reset", "module", "baseStyle", "header", "footer";

#wrapper {
	width: 100%;
	position: relative;
	overflow: hidden;
	overflow-y: auto;
	min-height: 100%;
}

#container {
	@include transition;
	position: relative;
	left: 0;
	//left: -100%;
	min-height: 100%;
	.open & {
		left: -100%;
	}
}

#contents {
	width: 100%;
	overflow: hidden;
	h1 {
		text-align: center;
		border-bottom: 2px solid $baseColor;
		padding: 15px 0 13px;
		text-align: center;
		font-weight: bold;
		background: #fff;
		@include box_shadow($bgColorDark 0px 1px 1px 0px);
		@include font_size(13);
	}
	> .container {
		margin: 0 10px 10px;
	}
}
#side_navi {
	width: 100%;
	min-height: 100%;
	position: absolute;
	background: #0075c2;
	right: -100%;
	top: 0;
	#side_header {
		height: 54px;
		overflow: hidden;
		border-bottom: 1px solid #014496;
		ul {
			background: #015cac;
			float: right;
			overflow: hidden;
			padding-right: 3px;
			li {
				float: left;
				a, span {
					background: #015cac;
					display: block;
					height: 54px;
					width: 55px;
					text-align: center;
					display: table-cell;
					vertical-align: middle;
					border-left: 1px solid #014496;
					img {
						//margin-top:
					}
				}
			}
		}
	}
	#side_user_info {
		padding: 20px 10px 10px 15px;
		overflow: hidden;
		.name_en {
			color: #fff;
			@include font_size(15);
			font-weight: bold;
			margin-bottom: 10px;
		}
		.name {
			@include font_size(11);
			margin-bottom: 15px;
			span {
				display: inline-block;
				color: #fff;
				&.name_ja {
					margin-right: 5px;
				}
				&.team {
					padding-left: 5px;
					border-left: solid 1px #6cf;
				}
				a {
					color: #fff;
				}
			}
		}
		.card_wrapper {
			float: left;
			.card {
				margin-left: 5px;
				margin-right: 10px;
				background-color: white;
				padding: 3px;
				@include border_radius(3px);
			}
		}
		.tweet_wrapper {
			overflow: hidden;
			padding-left: 10px;
			.tweet {
				@include borderBox;
				@include border_radius(6px);
				@include font_size(12);
				position: relative;
				background: white;
				border: 1px solid #dadada;
				padding: 5px;
				line-height: 1.5;
				margin-bottom: 5px;
				textarea {
					width: 100%;
					@include appearance;
					@include font_size(11);
					line-height: 1.3;
					border: none;
					@include box_shadow(rgba(0, 0, 0, 0) 0px 0px 0px 0px);
				}
				&:before {
					position: absolute;
					top: 15px;
					left: -5px;
					content: "";
					display: block;
					width: 6px;
					height: 7px;
					background: {
						//color: #f00;
						image: url(/src/img/my_page/serif.png);
						repeat: no-repeat;
						size: 6px 7px;
					}
				}
			}
			input[type="submit"]{
				background: transparent url(/src/img/my_page/btn_tweet.png) no-repeat;
				width: 101px;
				height: 30px;
				text-align: left;
				text-indent: -9999px;
				border: 1px solid rgba(255,255,255, .2);
				@include border_radius(3px);
			}
		}
	}
	#side_footer {
		background: #015cac;
		.nav_a {
			li {
				border-top: 1px solid #014496;
				a {
					@include font_size(13);
					color: #fff;
					font-weight: bold;
					text-decoration: none;
					display: block;
					padding: 20px 10px 18px;
					background: {
						image: url(/src/img/my_page/bullet_arrow_right_01.png);
						repeat: no-repeat;
						size: 8px auto;
						position: 98% center;
					}
				}
			}
		}
		.nav_b {
			background: #FFF;
			overflow: hidden;
			border-bottom: 1px solid #e0e0e0;
			li {
				border-top: 1px solid #e0e0e0;
				float: left;
				width: 50%;
				&.w {
					width: 100%;
					a {
						border: none;
						color: #000;
						text-align: center;
					}
				}
				a {
					@include font_size(11);
					color: #015cac;
					font-weight: bold;
					text-decoration: none;
					display: block;
					padding: 20px 10px 18px;
					border-right: 1px solid #e0e0e0;
				}
				&:nth-child(even) a {
					border-right: none;
				}
			}
		}
		.nav_c {
			background: #FFF;
			overflow: hidden;
			border-bottom: 2px solid #015cac;
			border-top: 1px solid #015cac;
			li {
				border-top: 1px solid #015cac;
				a {
					@include font_size(11);
					color: #000;
					font-weight: bold;
					text-decoration: none;
					text-align: center;
					display: block;
					padding: 20px 10px 18px;
				}
			}
		}
		#side_log_out {
			padding: 20px 10px 18px;
			text-align: center;
			color: #fff;
			border-top: 1px solid #014496;
			border-bottom: 1px solid #014496;
		}
	}
}

.modal {
	display: none;
	position: fixed;
	@include smooth;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #fcf4df;
	@include borderBox;
	padding-top: 60px;
	overflow-x: hidden;
	overflow-y: auto;
	z-index: 10;
	.modal_header {
		position: fixed;
		width: 100%;
		top: 0;
		left: 0;
		background: #f7e6c6;
		overflow: hidden;
		height: 30px;
		padding: 5px;
		border-bottom: 1px solid #eadbbe;
		z-index: 3;
		.back {
			position: absolute;
			top: 5px;
			left: 5px;
		}
		.tle {
			text-align: center;
			line-height: 30px;
			@include font_size(13);
			text-shadow: 0 2px 0 rgba(255, 255, 255, 0.7);
			font-weight: bold;
		}
	}
	.modal_body {
		padding-bottom: 15px;
		@include smooth;
		h3 {
			background: {
				image: url(/src/img/marker/bullet_a.png);
				repeat: no-repeat;
				position: 0 5px;
			}
			margin: 10px;
			padding-left: 12px;
			@include font_size(13);
			color: rgb(77, 61, 56);
			font-weight: bold;
		}
		p {
			@include font_size(12);
			line-height: 1.3;
			margin: 12px;
			&.cancel {
				margin: 0;
				padding: 10px 12px;
				background: #f7e6c6;
				border-top: 1px solid #eadbbe;
			}
			&.submit {
				margin: 0;
			}
		}
	}
}

.slide_modal {
	display: none;
	background: #f9f1dd;
	position: fixed;
	@include smooth;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	.header {
		background: #f7e6c6;
		position: relative;
		padding: 17px 0 14px;
		border-bottom: 1px solid #eadbbe;
		h2 {
			text-align: center;
			text-shadow: 0 1px 0 rgba(255, 255, 255, 0.7);
			color: #4d3d38;
			@include font_size(13);
			font-weight: bold;
		}
		.close {
			position: absolute;
			top: 7px;
			left: 10px;
		}
	}
}
#add_friend, #add_team_member{
	// display: block !important;
	// left: -100%;
	.stage {
		margin: 10px;
		background: #FFF;
		padding: 10px;
		background-color: #f7e6c6;
		@include border_radius(4px);
		@include box_shadow(1px 1.7px 2px white, inset 2.5px 4.3px 8px rgba(195, 162, 101, 0.15), inset 0 0 8px rgba(244, 229, 194, 0.75));
		@include borderBox;
		overflow: hidden;
		.tweet {
			padding-bottom: 10px;
			p {
				background: #FFF;
				padding: 10px;
				@include font_size(12);
				line-height: 1.3;
				border: 1px solid #dadada;
				position: relative;
				@include border_radius(5px);
				&:after {
					content: "";
					position: absolute;
					display: block;
					width: 8px;
					height: 6px;
					background: {
						image: url(/src/img/my_page/serif_horizontal.png);
						repeat: no-repeat;
						size: 8px 6px;
					}
					bottom: -6px;
					left: 30%;
				}
			}
		}
		.card_wrapper {
			width: 200%;
			overflow: hidden;
			.table {
				width: 50%;
				text-align: center;
				float: left;
				position: relative;
				margin-bottom: 10px;
				.card {
					height: 221px;
					position: relative;
					text-align: center;
					img {
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						margin: auto;
					}
				}
				.go_back {
					position: absolute;
					top: 50%;
					right: 0;
					margin-top: -18px - 50;
					padding: 50px 0 50px 50px;
					//background: #FFF;
				}
				.go_table {
					position: absolute;
					top: 50%;
					left: 0;
					margin-top: -18px - 50;
					padding: 50px 0 50px 50px;
					//background: #FFF;
				}
			}
			.back {
				display: none;
				width: 50%;
				text-align: center;
				float: right;
				position: relative;
				margin-bottom: 10px;
				.card {
					height: 221px;
					position: relative;
					text-align: center;
					img {
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						margin: auto;
					}
				}
				.go_table {
					position: absolute;
					top: 50%;
					left: 0;
					margin-top: -18px - 50;
					padding: 50px 50px 50px 0;
					//background: #FFF;
				}
			}
		}
	}
	.add_card {
		display: block;
		margin: 10px;
		background-color: #f26432;
		@include borderBox;
		@include border_radius(3px);
		@include box_shadow(0 1px 1px rgba(0, 0, 0, 0.15));
		text-decoration: none;
		padding: 18px 10px;
		text-align: center;
		font-weight: bold;
		text-shadow: 1px 1px 1px #c30;
		color: #fff;
		@include font_size(13);
	}
}