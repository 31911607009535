@import "base";

#header {
	width: 100%;
	position: relative;
	@include clearfix;
	background: $baseColor;
}

#symbol {
	float: left;
	padding: 10px 0 0 15px;
}

#global_navi {
	float: right;
	> li {
		display: table-cell;
		width: 54px;
		height: 53px;
		vertical-align: middle;
		text-align: center;
		border-left: 1px solid $baseColorDark;
	}
}
#navi_wrapper {
	clear: both;
	overflow: hidden;
	height: 0;
	width: 100%;
	@include transition;
}
#navi_wrapper.show {
	height: 247px;
}

#utility_navi {
	border-top: 1px solid $baseColorDark;
	li {
		border-bottom: 1px solid $baseColorDark;
		a {
			display: block;
			padding: 21px 15px 18px 20px;
			color: #fff;
			@include font_size(13);
			text-decoration: none;
			font-weight: bold;
			margin-right: 11px;
			background: {
				image: url(/src/img/header/arrow_global_navi_r.png);
				repeat: no-repeat;
				position: right center;
				size: auto 13px;
			}
		}
	}
}
#sub_navi {
	border: 1px solid $colorGrayLight;
	border-bottom: 2px solid $baseColor;
	background: #fff;
	li {
		border-bottom: 1px solid $colorGrayLight;
		a {
			display: block;
			padding: 15px;
			text-align: center;
			text-decoration: none;
			font-weight: bold;
			@include font_size(11);
		}
	}
}